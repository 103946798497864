import { useEffect } from 'react';
import './App.css';

function App() {
  const plugInUlr = process.env.REACT_APP_PLUGIN_URL
  
  useEffect(() => {
    const script = document.createElement('script');
    script.src = plugInUlr;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [plugInUlr]);

  return (
    <div className="App">
      {/*<button className='btn btn-outline-danger' data-chatbot-va="true" data-position="center-top">Chat with us</button>*/}
    </div>

    
  );
}

export default App;
